import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/app/components/Layout/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/src/components/registry.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fontsource/bai-jamjuree/200.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fontsource/bai-jamjuree/300.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fontsource/bai-jamjuree/400.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fontsource/bai-jamjuree/500.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fontsource/bai-jamjuree/600.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fontsource/bai-jamjuree/700.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fontsource/bai-jamjuree/700-italic.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fontsource/bai-jamjuree/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fontsource-variable/lexend/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fontsource-variable/inter/index.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fontsource-variable/roboto-flex/index.css")